import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {approveMarketingPeriod, devApproveMarketingPeriod, devClosePeriod, statLoad} from './stat.actions'
import { catchError, forkJoin, map, mergeMap, of } from 'rxjs'
import { StatService } from './stat.service'

@Injectable()
export class StatEffects {
  constructor(private actions$: Actions, private statService: StatService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(statLoad.start),
      mergeMap(({ req }) =>
        forkJoin([this.statService.load(req), this.statService.loadApprovedMarketingPeriods()]).pipe(
          map(([data, marketingMonths]) => statLoad.success({ data, approvedMarketingPeriods: marketingMonths })),
          catchError(error => of(statLoad.error({ error }))),
        ),
      ),
    ),
  )

    approveMarketingPeriod$ = createEffect(() =>
        this.actions$.pipe(
            ofType(approveMarketingPeriod.start),
            mergeMap(({ date }) =>
                this.statService.approveMarketingPeriod(date).pipe(
                    map(() => approveMarketingPeriod.success({ date })),
                    catchError(error => of(approveMarketingPeriod.error({ error }))),
                ),
            ),
        ),
    )

    devApproveMarketingPeriod$ = createEffect(() =>
        this.actions$.pipe(
            ofType(devApproveMarketingPeriod.start),
            mergeMap(({ date }) =>
                this.statService.devApproveMarketingPeriod(date).pipe(
                    map(() => devApproveMarketingPeriod.success({ date })),
                    catchError(error => of(devApproveMarketingPeriod.error({ error }))),
                ),
            ),
        ),
    )

    devClosePeriod$ = createEffect(() =>
        this.actions$.pipe(
            ofType(devClosePeriod.start),
            mergeMap(({ date }) =>
                this.statService.devClosePeriod(date).pipe(
                    map(() => devApproveMarketingPeriod.success({ date })),
                    catchError(error => of(devApproveMarketingPeriod.error({ error }))),
                ),
            ),
        ),
    )
}
