import { Injectable } from '@angular/core'
import { AccrualsReportRequest } from '../../../../common/models/accruals-report-request'
import { RequestService } from '../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { AccrualsReportResponse } from '../../../../common/models/accruals-report-response'
import { Datestamp } from 'common/models/datestamp'

@Injectable({
  providedIn: 'root',
})
export class StatService {
  constructor(private request: RequestService) {}

  load(req: AccrualsReportRequest): Observable<AccrualsReportResponse> {
    return this.request.post('/api/admin/reports/accruals', req)
  }

  loadApprovedMarketingPeriods(): Observable<Datestamp[]> {
    return this.request.post<unknown, Datestamp[]>('/api/admin/get/approved_marketing_months', {})
  }

  approveMarketingPeriod(date: Datestamp): Observable<void> {
    return this.request.post<Datestamp, void>('/api/admin/approve_marketing_result', date)
  }

  devApproveMarketingPeriod(date: Datestamp): Observable<void> {
    return this.request.post<Datestamp, void>('/api/admin/approve_marketing_result_dev', date)
  }

  devClosePeriod(date: Datestamp): Observable<void> {
    return this.request.post<Datestamp, void>('/api/admin/close_period_dev', date)
  }
}
